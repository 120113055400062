<template>
  <div class="card p-shadow-6">
    <h1>Manuais</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <DataTable
      :value="list"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      responsiveLayout="scroll"
      rowGroupMode="subheader"
      groupRowsBy="category"
      :expandableRowGroups="true"
      :expandedRowGroups.sync="expandedRowGroups"
      sortMode="single"
      sortField="category"
      :sortOrder="1"
      :scrollable="true"
      scrollHeight="flex"
    >
      <template #empty> Não existem manuais. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <template #header>
        <div
          class="table-header"
          v-if="$store.state.auth.user.isEsa || can('manageManuals')"
        >
          <Button icon="pi pi-plus" @click="newManual()" />
          <Button
            label="Categoria dos Manuais"
            class="p-ml-1"
            icon="pi pi-bars"
            @click="$router.push(`/manuals-categories`)"
          />
        </div>
      </template>
      <template #groupheader="slotProps">
        <div class="myGroupHeader">
          {{ slotProps.data.category }}
        </div>
      </template>
      <Column field="name" header="Manual" sortable filterMatchMode="contains">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.name.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column header="Categoria">
        <template #body="slotProps">
          {{ slotProps.data.category }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.categoryName.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column
        field="category"
        header="Categoria"
        sortable
        filterMatchMode="contains"
      >
        <template #body="slotProps">
          {{ slotProps.data.categoryName }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.categoryName.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column
        field="comment"
        header="Comentário"
        sortable
        filterMatchMode="contains"
      >
        <template #body="slotProps">
          {{ slotProps.data.comment }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.comment.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="
              $store.state.auth.user.isEsa || can('manageManuals')
            "
            icon="pi pi-pencil"
            v-tooltip.left="'Editar'"
            class="p-button-sm p-button-rounded p-button-success"
            @click="editManual(slotProps.index, slotProps.data)"
          />
          <Button
            v-if="
              $store.state.auth.user.isEsa || can('manageManuals')
            "
            icon="pi pi-trash"
            v-tooltip.left="'Eliminar'"
            class="p-button-sm p-button-rounded p-button-danger p-ml-1"
            @click="deleteManual(slotProps)"
          />
          <Button
            icon="pi pi-download"
            v-tooltip.left="'Download'"
            class="p-button-sm p-button-rounded p-button-info p-ml-1"
            @click="downloadFile(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="manualModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (manualModel.id == undefined ? 'Criar' : 'Actualizar') + ' Manual'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="vacationsForm" class="p-col-12" @submit.prevent="saveManual">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="manualModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('manualName') },
                  'form-control',
                ]"
                :name="'manualName'"
              />
              <label for="manualName">Nome do Manual</label>
            </span>
            <small v-if="errors.has('manualName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <dropdown
                v-model="manualModel.categoryId"
                :options="listCategories"
                :optionLabel="getCategoryLabel"
                optionValue="id"
                :filter="true"
                :name="'manualCategory'"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('manualCategory') },
                  'form-control',
                ]"
              />
              <label for="manualCategory">Categoria</label>
            </span>
            <small
              v-if="errors.has('manualCategory')"
              class="p-error"
              role="alert"
            >
              Categoria é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="manualModel.comment"
                v-bind:class="[
                  { 'p-invalid': errors.has('manualComment') },
                  'form-control',
                ]"
                :name="'manualComment'"
              />
              <label for="manualComment">Comentário</label>
            </span>
          </div>

          <div class="p-col-12 p-md-6 p-mt-2">
            <FileUpload
              ref="uploadInput"
              mode="basic"
              name="files[]"
              chooseLabel="Adicionar Ficheiro"
              @select="fileSelected"
            />
            <small v-if="manualModel.filesError" class="p-error" role="alert">
              Não foi adicionado nenhum ficheiro
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveManual"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeManualForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import manualsService from "../services/manuals.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "Manuals",
  data() {
    return {
      expandedRowGroups: [],
      loading: true,
      list: [],
      listCategories: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      manualModel: { showForm: false },
    };
  },
  async created() {
    this.listCategories = await this.getCategoriesList();
    this.list = await this.getList();
    this.setCategoryName();
    this.loading = false;
  },
  methods: {
    getList() {
      return manualsService.getList().then((response) => response);
    },
    getCategoriesList() {
      return manualsService.getCategoriesList().then((response) => response);
    },
    setCategoryName() {
      this.list = this.list.map((category) => ({
        ...category,
        category: this.getCategory(category),
      }));
    },
    getCategory(data) {
      let category = "";
      if (data.parentCategoryId != null) {
        category = `${data.parentCategoryName} | `;
      }
      category += data.categoryName;
      return category;
    },
    getCategoryLabel(data) {
      let category = "";
      if (data.parentId != null) {
        category = `${data.parentName} | `;
      }
      category += data.name;
      return category;
    },
    downloadFile(data) {
      return manualsService.getFile(data.id).then((response) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = response.file;
        downloadLink.download = `${data.name}.${response.extension}`;
        return downloadLink.click();
      });
    },
    closeManualForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.manualModel = { showForm: false });
    },
    newManual() {
      return (this.manualModel.showForm = true);
    },
    editManual(index, manual) {
      return (this.manualModel = {
        index: index,
        showForm: true,
        id: manual.id,
        name: manual.name,
        categoryId: manual.categoryId,
        comment: manual.comment,
      });
    },
    fileSelected() {
      this.manualModel.filesError = false;
      this.$validator.validateAll();
    },
    saveManual() {
      if (
        this.manualModel.id === undefined &&
        this.$refs.uploadInput.files.length == 0
      ) {
        this.manualModel.filesError = true;
      } else {
        this.manualModel.filesError = false;
      }
      this.$validator.validateAll().then((isValid) => {
        if (!isValid || this.manualModel.filesError) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.manualModel.id === undefined) {
          return this.saveNewManual();
        }

        return this.updateManual();
      });
    },
    saveNewManual() {
      let formData = new FormData();
      formData.append("name", this.manualModel.name);
      formData.append("category", this.manualModel.categoryId);
      formData.append("file", this.$refs.uploadInput.files[0]);
      if (this.manualModel.comment !== undefined) {
        formData.append("comment", this.manualModel.comment);
      }

      return manualsService.newManual(formData).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir o manual",
            life: 3000,
          });
        }
        response.category = this.getCategory(response);
        this.list.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Manual inserido",
          detail: "O Manual inserido com sucesso",
          life: 3000,
        });
        return this.closeManualForm();
      });
    },
    updateManual() {
      let formData = new FormData();
      formData.append("name", this.manualModel.name);
      formData.append("category", this.manualModel.categoryId);
      if (
        this.manualModel.comment !== undefined &&
        this.manualModel.comment !== null
      ) {
        formData.append("comment", this.manualModel.comment);
      }
      if (this.$refs.uploadInput.files.length > 0) {
        formData.append("file", this.$refs.uploadInput.files[0]);
      }
      return manualsService
        .updateManual(this.manualModel.id, formData)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o manual",
              life: 3000,
            });
          }
          response.category = this.getCategory(response);
          this.list.splice(this.manualModel.index, 1);
          this.list[this.manualModel.index] = response;
          this.$toast.add({
            severity: "success",
            summary: "Manual atualizado",
            detail: "O Manual atualizado com sucesso",
            life: 3000,
          });
          return this.closeManualForm();
        });
    },
    deleteManual(info) {
      this.$confirm.require({
        header: "Eliminar Manual",
        message: `Tem a certeza que pretende eliminar o manual ${info.data.name}?`,
        icon: "pi pi-info-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Cancelar",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return manualsService.delete(info.data.id).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao eliminar o manual",
                life: 3000,
              });
            }
            this.list.splice(info.index, 1);
            return this.$toast.add({
              severity: "success",
              summary: "Manual eliminado",
              detail: "O Manual eliminado com sucesso",
              life: 3000,
            });
          });
        },
        reject: () => {},
      });
    },
  },
};
</script>
<style>
.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
  background-color: #ff4910;
  color: #fff;
}
</style>
