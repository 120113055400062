import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class ManualsService {
  getCategoriesList() {
    return axios
      .get(`${config["apiUrl"]}manuals/categories`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getCategoriesParentList() {
    return axios
      .get(`${config["apiUrl"]}manuals/categories/parents`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getList() {
    return axios
      .get(`${config["apiUrl"]}manuals`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  newManual(bodyParams) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";

    return axios
      .post(`${config["apiUrl"]}manuals`, bodyParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  updateManual(manualId, bodyParams) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";

    return axios
      .post(`${config["apiUrl"]}manuals/${manualId}`, bodyParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  delete(manualId) {
    return axios
      .delete(`${config["apiUrl"]}manuals/${manualId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getFile(manualId) {
    return axios
      .get(`${config["apiUrl"]}manuals/${manualId}/file`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addCategory(bodyParams) {
    let headers = authHeader();
    return axios
      .post(`${config["apiUrl"]}manuals/categories`, bodyParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateCategory(categoryId, bodyParams) {
    let headers = authHeader();
    return axios
      .put(`${config["apiUrl"]}manuals/categories/${categoryId}`, bodyParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new ManualsService();
